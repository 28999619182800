
























































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { IMessageEmail } from '@/api/consoleApi/dto/campaigns.dto'
import { updateEmail, getEmailPreview, getEmailMappedLinkTags, uploadThumbnailImage } from '@/api/consoleApi/contents/emails'
import VsDragoTemplateModal from '@/modules/campaigns/components/VsDragoTemplateModal/Index.vue'
import VsDragoModal from '@/modules/campaigns/components/VsDragoModal/Index.vue'
import VsEmailContentTextEditorModal from '@/modules/campaigns/components/VsEmailContentTextEditorModal/Index.vue'
import VsImportHtmlModal from '@/modules/campaigns/components/VsImportHtmlModal/Index.vue'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import VsSpamTestModalModal from '@/modules/campaigns/components/VsSpamTestModalModal/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsEmailPlainContentModal from '@/modules/campaigns/components/VsEmailPlainContentModal/Index.vue'
import VsMapTagModal from '@/modules/campaigns/components/VsMapTagModal/Index.vue'
import { CustomField } from '@/utils/customFields'
import {
    contentContainsUnsubscriptionLink,
    contentHasPlaceHolderImages,
    contentHasCsaTags,
    contentContainsConfirmationLink,
    getCampaignLinks,
} from '@/utils/personalizations'
import html2canvas from 'html2canvas'
import { getFooterById } from '@/api/consoleApi/contents/footers'
import { UserModule } from '@/store/modules/user'
import VsActionCard from '@/components/VsActionCard/Index.vue'

@Component({
    name: 'VsEmailContentCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsConfirm,
        VsDragoTemplateModal,
        VsDragoModal,
        VsEmailContentTextEditorModal,
        VsImportHtmlModal,
        VsOverlayCard,
        VsPreviewModal,
        VsInlineErrorsCheck,
        VsEmailPlainContentModal,
        VsHintCard,
        VsSpamTestModalModal,
        VsMapTagModal,
        VsActionCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) message!: IMessageEmail
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: true, default: () => [] }) customFields!: CustomField[]
    @Prop({ required: false, default: () => [] }) lists!: any[]
    @Prop({ required: false, default: () => [] }) mappedTags!: any[]
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: 'campaign' }) contentMode!: 'campaign' | 'optin' | 'transactional'

    private iframeContent = ''
    private tags = false
    private contentError = false
    private loading = false
    private footerHtml = ''
    private footerPlain = ''
    private thumbnail = ''
    private mappedLinks: any[] = []
    // private mappedTags: any[] = []
    private editorTypes = [
        {
            title: this.$t('campaigns.editCampaign.sections.content.editorTypes.dragoTitle'),
            subtitle: this.$t('campaigns.editCampaign.sections.content.editorTypes.dragoSubtitle'),
            value: 'drago',
            hover: false,
            src: require('@/assets/img/campaigns/editor-drag-drop.jpg'),
            srcHover: require('@/assets/img/campaigns/editor-drag-drop-hover.jpg'),
        },
        {
            title: this.$t('campaigns.editCampaign.sections.content.editorTypes.texteditorTitle'),
            subtitle: this.$t('campaigns.editCampaign.sections.content.editorTypes.texteditorSubtitle'),
            value: 'text',
            hover: false,
            src: require('@/assets/img/campaigns/editor-testo.jpg'),
            srcHover: require('@/assets/img/campaigns/editor-testo-hover.jpg'),
        },
        {
            title: this.$t('campaigns.editCampaign.sections.content.editorTypes.importTitle'),
            subtitle: this.$t('campaigns.editCampaign.sections.content.editorTypes.importSubtitle'),
            value: 'import',
            hover: false,
            src: require('@/assets/img/campaigns/zip-e-url.jpg'),
            srcHover: require('@/assets/img/campaigns/zip-e-url-hover.jpg'),
        },
    ]

    private htmlPreview = ''
    $refs: any

    openTab () {
        this.$emit('open-tab', 'content')
    }

    get campaignContentStatus () {
        if (!this.message.html_content) return 'default'
        if (
            this.plainContentStatus === 'error' ||
            (this.contentMode !== 'optin' && this.hasUnsubscribeLinkStatus === 'error') ||
            (this.contentMode === 'optin' && this.hasConfirmationLinkStatus === 'error') ||
            this.hasPlaceHolderImageStatus === 'error' ||
            this.hasCsaTags === 'error' ||
            this.hasPlainContentCsaTags === 'error'
        ) return 'error'
        return 'success'
    }

    get emailId () {
        return this.message.id
    }

    get plainContentStatus () {
        return this.hasPlainContentStatus === 'error' ||
            (this.contentMode !== 'optin' && this.hasPlainContentUnsubscribeLinkStatus === 'error') ||
            (this.contentMode === 'optin' && this.hasPlainConfirmationLinkStatus === 'error') ||
            this.hasPlainContentCsaTags === 'error'
            ? 'error' : 'success'
    }

    get hasPlainContentStatus () {
        return this.message.plain_content?.trim() ? 'success' : 'error'
    }

    get hasPlainContentUnsubscribeLinkStatus () {
        return contentContainsUnsubscriptionLink(this.message.plain_content?.trim()) ||
            contentContainsUnsubscriptionLink(this.footerPlain?.trim()) ? 'success' : 'error'
    }

    get hasPlainContentCsaTags () {
        return contentHasCsaTags(this.message.plain_content?.trim()) ||
            contentHasCsaTags(this.footerPlain?.trim()) ? 'success' : 'error'
    }

    get hasPlainConfirmationLinkStatus () {
        return contentContainsConfirmationLink(this.message.plain_content?.trim()) ||
            contentContainsConfirmationLink(this.footerPlain?.trim()) ? 'success' : 'error'
    }

    get hasUnsubscribeLinkStatus () {
        return contentContainsUnsubscriptionLink(this.message.html_content) ||
            contentContainsUnsubscriptionLink(this.footerHtml) ? 'success' : 'error'
    }

    get hasConfirmationLinkStatus () {
        return contentContainsConfirmationLink(this.message.html_content) ||
            contentContainsConfirmationLink(this.footerHtml) ? 'success' : 'error'
    }

    get hasPlaceHolderImageStatus () {
        return contentHasPlaceHolderImages(this.message.html_content) ? 'error' : 'success'
    }

    get hasCsaTags () {
        return contentHasCsaTags(this.message.html_content) ||
            contentHasCsaTags(this.footerHtml) ? 'success' : 'error'
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get contentType () {
        if (!this.message.html_content) return ''
        if (!this.message.project_id || this.message.project_id === '0') return this.$t('campaigns.editCampaign.sections.content.texteditor')
        return this.$t('campaigns.editCampaign.sections.content.drago')
    }

    get user () {
        return UserModule.user
    }

    get hasBehavioralTag () {
        return this.user.configuration.rules.behavioralTag
    }

    get campaignLinks () {
        return [...new Set(getCampaignLinks(this.message, this.footerHtml))]
    }

    get clickTags () {
        return this.mappedTags.filter((tag: any) => {
            return tag.event === 'click'
        })
    }

    get openTags () {
        return this.mappedTags.filter((tag: any) => {
            return tag.event === 'open'
        })
    }

    get screenShotUrl () {
        if (this.message.thumbnail_url) {
            return this.message.thumbnail_url + `?t=${Date.now()}`
        }
        return ''
    }

    get Mixpanel () {
        return UserModule.Mixpanel
    }

    private trackMixpanelEvent (eventName: string) {
        if (this.Mixpanel) {
            this.Mixpanel.track(
                eventName,
                {
                    distinct_id: this.user._id,
                    'Campaign Id': this.campaignId,
                    'Plan Type': this.user.configuration.name || '',
                },
            )
        }
    }

    async beforeMount () {
        this.getEmailLinkTags()
        if (!this.message.thumbnail_url && this.message.html_content) {
            this.getPreview()
        }
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    private async getContent () {
        await this.getPreview()
        this.$emit('content-saved')
    }

    private getContentAndOpenDrago (projectId: any) {
        this.getContent()
        this.$refs.vsDragoModal.openModal({
            ...this.message,
            project_id: projectId,
        }, this.customFields)
    }

    private async saveContent (data: any) {
        this.$refs.vsEmailContentTextEditorModal.loading = true
        try {
            await updateEmail(
                this.message.id,
                {
                    content: {
                        html: data.content,
                    },
                    footer_id: data.footer || 0,
                    thumbnail_url: data.thumbnail_url || '',
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.content.contentSaved'),
                aspect: VsToastAspectEnum.success,
            })
            await this.getPreview()
            this.$emit('content-saved')

            this.$refs.vsEmailContentTextEditorModal.closeModal()
            this.$refs.vsEmailContentTextEditorModal.loading = false
        } catch (e) {
            this.$refs.vsEmailContentTextEditorModal.loading = false
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.content.contentSavedError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async savePlainContent (plainContent: any) {
        this.$refs.vsEmailPlainContentModal.loading = true
        try {
            await updateEmail(
                this.message.id,
                {
                    content: {
                        plain: plainContent,
                    },
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.content.onlyTextSaved'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('content-saved')

            this.$refs.vsEmailPlainContentModal.loading = false
            this.$refs.vsEmailPlainContentModal.closeModal()
        } catch (e) {
            this.$refs.vsEmailPlainContentModal.loading = false
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.content.onlyTextSavedError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async importContent () {
        await this.getPreview()
        this.$emit('content-saved')
    }

    @Watch('message.footer_id', { immediate: true, deep: true })
    private async getFooter () {
        if (this.message.footer_id) {
            try {
                const resp = await getFooterById(this.message.footer_id)
                this.footerHtml = resp.data.data.html
                this.footerPlain = resp.data.data.plain
            } catch (e) {
                console.log(e)
                this.footerHtml = ''
                this.footerPlain = ''
            }
        } else {
            this.footerHtml = ''
            this.footerPlain = ''
        }
    }

    private async getPreview () {
        try {
            const response = await getEmailPreview(this.message.id)
            this.htmlPreview = response.data.replace('<head>', '<head><style>body{overflow:hidden;}</style>')
        } catch (e) {
            console.log(e)
        }
    }

    private openDragoModal () {
        this.$refs.vsDragoModal.openModal(this.message, this.customFields)
    }

    private openDragoTemplateModal () {
        this.$refs.vsDragoTemplateModal.openModal(this.message.id)
    }

    private openTextModal () {
        this.$refs.vsEmailContentTextEditorModal.openModal(
            this.customFields,
            {
                content: this.message.html_content,
                footer: this.message.footer_id,
            },
        )
    }

    private openCreateContentModal (type: string) {
        switch (type) {
            case 'drago':
                this.openDragoTemplateModal()
                break
            case 'import':
                this.openImportModal('url')
                break
            case 'text':
                this.openTextModal()
                break
            default:
                break
        }
    }

    private openImportModal (page: string) {
        this.$refs.vsImportHtmlModal.openModal(this.message.id, page)
    }

    public openPreviewModal () {
        this.$refs.previewModal.openModalNew(this.message, 'email')
    }

    private openEditContent () {
        if (!this.message.project_id || this.message.project_id === '0') {
            this.openTextModal()
            return
        }
        this.openDragoModal()
    }

    private async deleteCampaignContent () {
        try {
            await this.$refs.confirmDeleteContent.openConfirm()
            await this.saveContent({
                content: '',
                footer: 0,
                thumbnail_url: '',
            })
            this.getEmailLinkTags()
        } catch (e) {
        }
    }

    private async getScreenshot (e: any) {
        const iframeEl: any = e.target
        if (!iframeEl) return
        this.loading = true
        const body = iframeEl.contentWindow.document.getElementsByTagName('BODY')[0]

        const canvas = await html2canvas(body, {
            windowWidth: 700,
            windowHeight: 1140,
            width: 700,
            height: 1140,
            proxy: '/app/userapi/users/screenshot-images-proxy',
        })

        try {
            let base64 = canvas.toDataURL('image/jpeg', 0.1)
            base64 = await this.processImage(base64)

            await uploadThumbnailImage(
                this.message.id,
                {
                    base64_image: base64,
                },
            )
            this.$emit('screenshot-saved')
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dell\'anteprima',
                timeout: 3000,
                aspect: VsToastAspectEnum.warning,
            })
            console.log(e)
        }
        this.loading = false
    }

    async processImage (base64: string, min_image_size = 20) {
        if (base64) {
            const old_size = this.calcImageSize(base64)
            if (old_size > min_image_size) {
                const resized = await this.reduceImageFileSize(base64)
                return resized
            } else {
                return base64
            }
        } else {
            return base64
        }
    }

    calcImageSize (base64: string) {
        let y = 1
        if (base64.endsWith('==')) {
            y = 2
        }
        const x_size = (base64.length * (3 / 4)) - y
        return Math.round(x_size / 1024)
    }

    async reduceImageFileSize (base64Str: string, MAX_WIDTH = 400, MAX_HEIGHT = 500) {
        const resized_base64: string = await new Promise((resolve) => {
            const img = new Image()
            img.src = base64Str
            img.onload = () => {
                const canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')
                if (!ctx) {
                    return base64Str
                }
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL('image/jpeg', 0.1))
            }
        })
        return resized_base64
    }

    private isExternalUrl (stringUrl: string) {
        try {
            const url = new URL(stringUrl)
            const hostName = window.location.hostname
            if (!url.host || url.host === hostName) {
                return false
            } else {
                return true
            }
        } catch (e) {
            return false
        }
    }

    private async getEmailLinkTags () {
        try {
            const resp = await getEmailMappedLinkTags(this.message.id)
            this.mappedLinks = resp.data.data
        } catch (e) {
            this.mappedLinks = []
            console.log(e)
        }
    }

    openSpamTestModal () {
        this.trackMixpanelEvent('Campaign SpamTest Requested')
        this.$refs.vsSpamTestModal.openModal(this.message.id)
    }
}
